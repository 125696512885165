
export interface Address {
  city: string;
  country: string;
  number: string;
  postcode: string;
  street: string;
}

export interface AggregatingReportRow {
  value: ArrayOfAggregationReportResultField;
}

export interface AggregationReportResultField {
  fieldname: string;
  value: string;
}

export enum AnredeKz {
FIRMA,
FIRMA_OHNE_ANDRUCK,
JURISTISCHE_PERSON_OHNE_ANDRUCK,
HAUSVERWALTUNG_OHNE_ANDRUCK,
UNBEKANNT_OHNE_ANDRUCK,
HERR_UND_FRAU,
HERR_UND_FRAU_OHNE_ANDRUCK,
EHELEUTE,
EHELEUTE_OHNE_ANDRUCK,
FRAU,
FRAU_OHNE_ANDRUCK,
FRAEULEIN,
FRAEULEIN_OHNE_ANDRUCK,
HERR,
HERR_OHNE_ANDRUCK
}

export interface Answer {
  answerElement: ArrayOfAnswerElement;
  informationtext?: string;
}

export interface AnswerElement {
  element: TemplateElement;
  input?: ArrayOfAnswerKeyValue;
}

export enum AnswerElementKey {
SIMPLE_VALUE,
MULTI_VALUE,
ADDRESS,
PDF_DOCUMENT,
MULTI_FILES
}

export interface AnswerKeyValue {
  key: AnswerElementKey;
  value?: string;
}

export interface AnswerStateContext {
  value: ArrayOfAnswerStateContextKeyValue;
}

export enum AnswerStateContextKey {
HIGHLIGHT_ELEMENT,
CUSTOMER_MESSAGE_FOR_ELEMENT
}

export interface AnswerStateContextKeyValue {
  key: AnswerStateContextKey;
  value?: string;
}

export interface AnswerWizardConfig {
  disabledFirstStep?: AnswerWizardStep;
  numberOfSteps: number;
  wizardSteps: ArrayOfAnswerWizardStep;
}

export interface AnswerWizardStep {
  active?: boolean;
  backButtonTitle?: string;
  completed?: boolean;
  disabled?: boolean;
  doNotShowNextButton?: boolean;
  execSaveMethodOnNextButton?: boolean;
  expanded?: boolean;
  informationtext?: string;
  nextButtonTitle?: string;
  step: number;
  title: string;
}

export interface ArrayOfAggregatingReportRow {
  value: AggregatingReportRow[];
}

export interface ArrayOfAggregationReportResultField {
  value: AggregationReportResultField[];
}

export interface ArrayOfAnswerElement {
  value: AnswerElement[];
}

export interface ArrayOfAnswerKeyValue {
  value: AnswerKeyValue[];
}

export interface ArrayOfAnswerStateContextKeyValue {
  value: AnswerStateContextKeyValue[];
}

export interface ArrayOfAnswerWizardStep {
  value: AnswerWizardStep[];
}

export interface ArrayOfAuthKeyValue {
  value: AuthKeyValue[];
}

export interface ArrayOfAuthorContextKeyValue {
  value: AuthorContextKeyValue[];
}

export interface ArrayOfBrokerContextRange {
  value: BrokerContextRange[];
}

export interface ArrayOfContactType {
  value: ContactType[];
}

export interface ArrayOfCustomerHistory {
  value: CustomerHistory[];
}

export interface ArrayOfDashboardReportRow {
  value: DashboardReportRow[];
}

export interface ArrayOfDependency {
  value: Dependency[];
}

export interface ArrayOfEmailAdresses {
  value: string[];
}

export interface ArrayOfExtendedAuthKeyValue {
  value: ExtendedAuthKeyValue[];
}

export interface ArrayOfExtraElement {
  value: ExtraElement[];
}

export interface ArrayOfKeyValue {
  value: KeyValue[];
}

export interface ArrayOfLinkEvent {
  value: LinkEvent[];
}

export interface ArrayOfLinkEventType {
  value: LinkEventType[];
}

export interface ArrayOfReportAggregationField {
  value: ReportAggregationField[];
}

export interface ArrayOfReportGroupField {
  value: ReportGroupField[];
}

export interface ArrayOfReportingFilterCriteriaListItem {
  value: ReportingFilterCriteriaListItem[];
}

export interface ArrayOfSourceSystem {
  value: SourceSystem[];
}

export interface ArrayOfString {
  value: string[];
}

export interface ArrayOfTemplateConfigKeyValue {
  value: TemplateConfigKeyValue[];
}

export interface ArrayOfTemplateElement {
  value: TemplateElement[];
}

export interface ArrayOfTenant {
  value: Tenant[];
}

export interface AuthContext {
  value: ArrayOfAuthKeyValue;
}

export enum AuthKey {
KNOWN_DATE_BIRTHDATE,
KNOWN_DATE_POSTCODE,
KNOWN_DATE_POSTCODE_AND_BIRTHDATE,
POS_SECURITY_TOKEN,
TAN_TOKEN,
TAN
}

export interface AuthKeyValue {
  key: AuthKey;
  value?: string;
}

export interface AuthResult {
  key?: AuthResultKey;
  message?: string;
  success: boolean;
  value?: string;
}

export enum AuthResultKey {
TAN_RETRY,
TAN_TOKEN
}

export enum AuthType {
TOKEN,
KNOWN_DATE_BIRTHDATE,
KNOWN_DATE_POSTCODE,
KNOWN_DATE_POSTCODE_AND_BIRTHDATE,
TAN
}

export enum AuthorContextKey {
VERMITTLER_NR,
TEAM_NR,
ABTEILUNGS_NR,
USERID
}

export interface AuthorContextKeyValue {
  key: AuthorContextKey;
  position: number;
  value: string;
}

export interface BankData {
  accountHolderName: string;
  bankName: string;
  bic: string;
  iban: string;
}

export interface BrokerContext {
  brokerId?: string;
}

export interface BrokerContextRange {
  from: string;
  to: string;
}

export enum CampaignContextType {
VERMITTLER_NR
}

export interface CheckAnswerCompleteRequest {
  authContext?: AuthContext;
  correlationId?: string;
  id: string;
}

export interface CheckAnswerCompleteResponse {
  answered: boolean;
}

export interface ClientFieldValidationResult {
  error?: string;
  payload?: ArrayOfKeyValue;
  valid: boolean;
}

export enum ContactType {
CELLPHONE,
EMAIL,
LETTER
}

export interface CreateCampaignRequest {
  campaignContext: string;
  correlationId?: string;
  linkContext: LinkContext;
  shortname?: string;
}

export interface CreateCampaignResponse {
  id: string;
  url: string;
}

export interface CreateLinkRequest {
  context: LinkContext;
  correlationId?: string;
}

export interface CreateLinkResponse {
  correlationId?: string;
  id: string;
  shortId: string;
  shortUrl: string;
  url: string;
}

export interface CreateTemplateRequest {
  correlationId?: string;
  template: Template;
}

export interface CreateTemplateResponse {
  template: Template;
}

export interface CustomerContactEmailChangeRequest {
  authContext?: AuthContext;
  correlationId?: string;
  email: string;
  linkId: string;
}

export interface CustomerContactEmailChangeResponse {
  success: boolean;
}

export interface CustomerContactStatusRequest {
  authContext?: AuthContext;
  correlationId?: string;
  linkId: string;
}

export interface CustomerContactStatusResponse {
  email: string;
  emailConfirment: CustomerContactStatusVerified;
  mobil: string;
  mobilConfirment: CustomerContactStatusVerified;
}

export enum CustomerContactStatusVerified {
TRUE,
FALSE,
UNBEKANNT
}

export interface CustomerContext {
  address?: Address;
  cellPhoneNumber?: string;
  contactType: ContactType;
  email?: string;
  firstname?: string;
  gender?: Gender;
  lastname?: string;
  partnerNo?: string;
  salutation1?: string;
  salutation2?: string;
}

export interface CustomerHistory {
  belegart?: string;
  erstelldatum: Date;
  linkId: string;
  risiko?: string;
  schadennummer?: string;
  sparte?: string;
  vcdocumenttile?: string;
  vsnr?: string;
}

export interface DashboardReportRow {
  authorUserid?: string;
  authtype: string;
  brokerno?: string;
  contact?: string;
  contactBy?: string;
  countFailed?: number;
  created: Date;
  deleted: boolean;
  extid: string;
  firstCompleted?: Date;
  firstOpened?: Date;
  firstOpenedPreauth?: Date;
  firstname?: string;
  id: number;
  lastFailed?: Date;
  lastname?: string;
  marketingcampaign?: string;
  partnerNo?: string;
  personalizationLevel: string;
  sent?: Date;
  shortId: string;
  shortlinkcampaign?: string;
  sourcesystem: string;
  templateExternalid: string;
  tenant: string;
  validfrom?: Date;
  validuntil?: Date;
}

export interface DeleteLinkReportingFilterRequest {
  correlationId?: string;
  createdBy: string;
  externalId: string;
}

export interface DeleteLinkReportingFilterResponse {
  items: ArrayOfReportingFilterCriteriaListItem;
}

export interface Dependency {
  comparison: DependencyComparisonOperator;
  elementid: string;
  value: string;
}

export enum DependencyComparisonOperator {
EQUAL,
NOT_EQUAL,
CONTAINS,
EXISTS,
NOT_EXISTS
}

export interface DependencyConfig {
  dependencies: ArrayOfDependency;
  operator: DependencyOperator;
}

export enum DependencyOperator {
AND,
OR
}

export enum ElementType {
INFORMATION,
DOCUMENT_DOWNLOAD,
CONFIRMATION,
CONFIRMATION_POST_REG,
DI_COM_KOM_UPDATE_CONFIRMATION,
SINGLE_CHOICE,
MULTI_CHOICE,
DATA_INPUT_ADDRESS,
DATA_INPUT_TEXT,
DATA_INPUT_TEXTAREA,
DATA_INPUT_NUMBER,
DATA_INPUT_DATE,
FILE_UPLOAD,
EMAIL_INPUT_WITH_VALIDATION,
PHONE_INPUT_WITH_VALIDATION,
BANK_INPUT,
BANK_INPUT_ONLY_IBAN,
MAIL_DOUBLE_OPT_IN,
PHONE_DOUBLE_OPT_IN,
PHONE_INPUT_WITH_VALIDATION_SIGNUP,
SINGLE_CHOICE_SIGNUP
}

export enum ExtendedAuthKey {
CELLPHONE,
EMAIL
}

export interface ExtendedAuthKeyValue {
  key: ExtendedAuthKey;
  value?: string;
}

export interface ExtraElement {
  externalid: string;
  html?: string;
  informationtext?: string;
  phase?: Phase;
  position: number;
  templateElementConfig?: ArrayOfKeyValue;
  type: ExtraElementType;
}

export enum ExtraElementType {
CUSTOMER_CONTACT,
AUTH,
SUCCESS
}

export enum Gender {
FRAU,
HERR,
DIVERS
}

export interface GetContactConfirmationRequest {
  authContext?: AuthContext;
  contactType: ContactType;
  correlationId?: string;
  linkId: string;
}

export interface GetContactConfirmationResponse {
  confirmed: boolean;
  error: string;
}

export interface GetContactInformationConfirmedRequest {
  authContext?: AuthContext;
  correlationId?: string;
  linkId: string;
}

export interface GetContactInformationConfirmedResponse {
  confirmed: boolean;
  error: string;
}

export interface GetCustomerHistoryRequest {
  authContext?: AuthContext;
  correlationId?: string;
  currentPage?: number;
  filterCriteria?: ReportingFilterCriteria;
  linkId: string;
  onlyCount?: boolean;
  orderBy?: string;
  pageSize?: number;
  sortOrder?: string;
}

export interface GetCustomerHistoryResponse {
  customer: CustomerContext;
  list: ArrayOfCustomerHistory;
  tenant: Tenant;
  vermittlerDaten?: VermittlerDaten;
}

export interface GetDeferredLinkPartRequest {
  authContext?: AuthContext;
  correlationId?: string;
  id: string;
  templateElementExternalId: string;
}

export interface GetDeferredLinkPartResponse {
  answerElement: AnswerElement;
  informationtext?: string;
}

export interface GetFieldValidationResultRequest {
  answer: Answer;
  authContext?: AuthContext;
  correlationId?: string;
  elementId: string;
  linkId: string;
  value: string;
}

export interface GetFieldValidationResultResponse {
  result: ClientFieldValidationResult;
}

export interface GetKundenpostfachLinkRequest {
  authContext?: AuthContext;
  correlationId?: string;
  linkId: string;
}

export interface GetKundenpostfachLinkResponse {
  correlationId?: string;
  kundenpostfachlink: string;
}

export interface GetLinkCompleteRequest {
  correlationId?: string;
  id: string;
}

export interface GetLinkCompleteResponse {
  answer: Answer;
  context: LinkContext;
  shortUrl: string;
  url: string;
}

export interface GetLinkEventRequest {
  correlationId?: string;
  linkId: string;
  showAll?: boolean;
}

export interface GetLinkEventResponse {
  error?: string;
  eventList?: ArrayOfLinkEvent;
}

export interface GetLinkReportingAggregatingRequest {
  aggregation: ReportAggregation;
  correlationId?: string;
  filterCriteria: ReportingFilterCriteria;
}

export interface GetLinkReportingAggregatingResponse {
  reportdata: ArrayOfAggregatingReportRow;
}

export interface GetLinkReportingFilterListRequest {
  correlationId?: string;
  createdBy: string;
  createdByFilter?: string;
}

export interface GetLinkReportingFilterListResponse {
  items: ArrayOfReportingFilterCriteriaListItem;
}

export interface GetLinkReportingRequest {
  correlationId?: string;
  currentPage?: number;
  filterCriteria: ReportingFilterCriteria;
  onlyCount?: boolean;
  orderBy?: string;
  pageSize?: number;
  sortOrder?: string;
}

export interface GetLinkReportingResponse {
  count?: number;
  reportdata: ArrayOfDashboardReportRow;
}

export interface GetLinkRequest {
  authContext?: AuthContext;
  correlationId?: string;
  id: string;
}

export interface GetLinkResponse {
  answer?: Answer;
  answerStateContext?: AnswerStateContext;
  answered: boolean;
  authExtendedInfo: ArrayOfExtendedAuthKeyValue;
  authResult: AuthResult;
  authType: AuthType;
  customerContext?: CustomerContext;
  expired: boolean;
  extraElements: ArrayOfExtraElement;
  historyID?: string;
  message?: string;
  needsAnswer: boolean;
  needsAuth: boolean;
  redirect?: RedirectType;
  redirectURL?: string;
  templateConfig: ArrayOfTemplateConfigKeyValue;
  tosAcceptanceRequired?: boolean;
  updateAllowed: boolean;
  vermittlerDaten?: VermittlerDaten;
  waitUntil: Date;
}

export interface GetShortLinkRequest {
  correlationId?: string;
  id: string;
}

export interface GetShortLinkResponse {
  redirectUrl: string;
}

export interface GetTemplateRequest {
  correlationId?: string;
  id: string;
}

export interface GetTemplateResponse {
  template: Template;
}

export interface IsTosAcceptedRequest {
  correlationId?: string;
  linkId: string;
  tosType: TosType;
}

export interface IsTosAcceptedResponse {
  accepted: boolean;
  correlationId?: string;
  tosType: TosType;
}

export interface KeyValue {
  key: string;
  value: string;
}

export interface LandingpageEmailWithTokenRequest {
  correlationId?: string;
  email?: string;
  landingpageRequestType: LandingpageRequestType;
  lpfromlinktoken?: string;
}

export interface LandingpageRequest {
  correlationId?: string;
  email?: string;
  landingpageRequestType: LandingpageRequestType;
  nachname: string;
  plz: string;
  tenant: Tenant;
  vorname: string;
  vsnr: string;
}

export enum LandingpageRequestResultLinkType {
SIGNUP,
SIGNOFF,
CHANGE_CONTACT,
MAILBOX,
LIST_EMAILADRESSSES
}

export enum LandingpageRequestType {
SIGNUP,
SIGNOFF,
CHANGE_CONTACT,
EMAILADRESSES
}

export interface LandingpageResponse {
  arrayOfEmailAdresses?: ArrayOfEmailAdresses;
  correlationId?: string;
  generatedLink?: string;
  inputValid: boolean;
  landingpageRequestResultLinkType?: LandingpageRequestResultLinkType;
  landingpageRequestType: LandingpageRequestType;
  technicalError?: boolean;
  tenant: Tenant;
}

export interface LinkContext {
  answerStateContext?: AnswerStateContext;
  authContext?: AuthContext;
  brokerContext?: BrokerContext;
  customerContext?: CustomerContext;
  linkProperties: LinkProperties;
  productContext?: ProductContext;
  systemContext: SystemContext;
}

export interface LinkEvent {
  event: LinkEventType;
  timestamp: Date;
  value?: string;
}

export enum LinkEventType {
LINK_CREATED,
LINK_SENT,
LINK_RESENT,
LINK_OPENED_PREAUTH,
LINK_AUTH_FAILED,
LINK_OPENED,
LINK_SAVED_TEMPORARY,
LINK_ANSWERED,
LINK_COMPLETED,
LINK_CREATED_NEW_LINK,
EXTERNAL
}

export interface LinkProperties {
  authType: AuthType;
  campaign?: string;
  caseToken?: string;
  donotSend?: boolean;
  forceSend?: boolean;
  personalizationLevel?: PersonalizationLevel;
  redirect?: RedirectType;
  redirectURL?: string;
  replyTo?: string;
  sendFrom?: string;
  sendFromText?: string;
  showInCustomerHistory?: boolean;
  templateId: string;
  tenant: Tenant;
  tosAcceptanceRequired?: boolean;
  validity?: TimeRange;
}

export interface ObjectFactory {}

export interface PartnernoMigrationRequest {
  correlationId?: string;
  partnernoNach: string;
  partnernoVon: string;
}

export interface PartnernoMigrationResponse {
  correlationId?: string;
  informationtext?: string;
  success: boolean;
}

export enum PersonalizationLevel {
INDIVIDUAL,
GROUP,
PUBLIC
}

export enum Phase {
PREAUTH,
AUTHENTICATED
}

export interface ProductContext {
  keys: ArrayOfKeyValue;
}

export enum RedirectType {
IMMEDIATE,
AFTER_ANSWER
}

export interface ReportAggregation {
  aggregation: ArrayOfReportAggregationField;
  groupBy?: ArrayOfReportGroupField;
}

export enum ReportAggregationField {
COUNT_LINK_SENT,
COUNT_FIRST_OPENED,
COUNT_FIRST_COMPLETED
}

export enum ReportGroupField {
BROKER_NO
}

export interface ReportingFilterCriteria {
  authorContextFilter?: ArrayOfAuthorContextKeyValue;
  brokerContextFilter?: ArrayOfBrokerContextRange;
  campaign?: string;
  contact?: string;
  contactType?: ArrayOfContactType;
  createdBetween?: TimeRange;
  createdBetweenAsTimeInterval?: TimeInterval;
  customername?: string;
  eventType?: ArrayOfLinkEventType;
  linkid?: string;
  notEventType?: ArrayOfLinkEventType;
  partnerNo?: string;
  personalizationLevel?: PersonalizationLevel;
  sourceSystem?: ArrayOfSourceSystem;
  templateId?: ArrayOfString;
  tenant?: ArrayOfTenant;
  validity?: TimeRange;
}

export interface ReportingFilterCriteriaListItem {
  createdBy: string;
  externalid?: string;
  filterCriteria: ReportingFilterCriteria;
  name: string;
  orderBy: string;
  own: boolean;
  sortOrder: string;
}

export enum ResendLinkError {
NO_CONTACT_DATA,
LINK_NOT_FOUND,
NO_INDIVIDUAL_LINK,
LINK_EXPIRED,
OTHER_ERROR
}

export interface ResendLinkRequest {
  correlationId?: string;
  id: string;
  recipient?: string;
}

export interface ResendLinkResponse {
  error?: ResendLinkError;
  informationtext?: string;
  success: boolean;
}

export interface SaveContactConfirmationRequest {
  correlationId?: string;
  linkId: string;
  token: string;
}

export interface SaveContactConfirmationResponse {
  error?: string;
  redirectUrl: string;
  success: boolean;
}

export interface SaveLinkEventRequest {
  correlationId?: string;
  event: LinkEvent;
  linkId: string;
}

export interface SaveLinkEventResponse {
  error?: string;
  eventList?: ArrayOfLinkEvent;
}

export interface SaveLinkReportingFilterRequest {
  correlationId?: string;
  createdBy: string;
  filterCriteriaItem: ReportingFilterCriteriaListItem;
}

export interface SaveLinkReportingFilterResponse {
  error?: string;
  items: ArrayOfReportingFilterCriteriaListItem;
  success: boolean;
}

export interface SaveLinkRequest {
  answer: Answer;
  authContext?: AuthContext;
  correlationId?: string;
  id: string;
}

export interface SaveLinkResponse {
  message?: string;
  success: boolean;
  valid?: boolean;
}

export interface SaveLinkTemporaryRequest {
  answer: Answer;
  answerStateContext?: AnswerStateContext;
  authContext?: AuthContext;
  correlationId?: string;
  id: string;
}

export interface SaveLinkTemporaryResponse {
  message?: string;
  success: boolean;
}

export interface SaveTemplateRequest {
  correlationId?: string;
  template: Template;
}

export interface SaveTemplateResponse {
  template: Template;
}

export interface SelectItem {
  label: string;
  value: string;
}

export enum SendContactConfirmationActionType {
CONTACT_CONFIRMATION_CHANGE_MOBILE,
NOTHING,
CONTACT_CONFIRMATION_CHANGE_EMAIL,
CONTACT_CONFIRMATION_CHANGE_EMAIL_AND_MOBIL,
SIGN_UP
}

export interface SendContactConfirmationRequest {
  authContext?: AuthContext;
  contact: string;
  contactType: ContactType;
  correlationId?: string;
  linkId: string;
  sendContactConfirmationAction?: SendContactConfirmationActionType;
}

export interface SendContactConfirmationResponse {
  error?: string;
  success: boolean;
}

export interface SendTosAcceptedRequest {
  authKeyValue: AuthKeyValue;
  correlationId?: string;
  linkId: string;
  tosType: TosType;
}

export interface SendTosAcceptedResponse {
  correlationId?: string;
  error?: string;
  success: boolean;
  tosType?: TosType;
}

export interface SendTransactionConfirmationRequest {
  correlationId?: string;
  linkId: string;
  tanType: ExtendedAuthKey;
}

export interface SendTransactionConfirmationResponse {
  error?: string;
  success: boolean;
}

export enum SourceSystem {
MOBIVERS_DIREKT,
APOLLO_PORTAL,
RHION_PORTAL,
CBS,
WESER,
LINKED_APP,
LINKED_APP_BATCH,
KUNDENPOSTFACH,
ALBATROS,
ICIS,
JUMP_24
}

export interface SystemContext {
  author: ArrayOfAuthorContextKeyValue;
  id: SourceSystem;
}

export interface Template {
  customizingid?: string;
  descr?: string;
  externalid: string;
  extraElements?: ArrayOfExtraElement;
  informationtext?: string;
  needsAnswer?: boolean;
  templateConfig?: ArrayOfKeyValue;
  templateElements: ArrayOfTemplateElement;
  templateExternalConfig?: ArrayOfTemplateConfigKeyValue;
  updateAllowed?: boolean;
}

export enum TemplateConfigKey {
SUBMIT_BUTTON_TEXT,
WIZARD_CONFIG,
HIDE_POSTFACH_LINK
}

export interface TemplateConfigKeyValue {
  key: TemplateConfigKey;
  value?: string;
}

export interface TemplateElement {
  deferred: boolean;
  dependencyConfig?: DependencyConfig;
  externalid: string;
  heading: string;
  htmlAfter?: string;
  informationtext?: string;
  mandatory: boolean;
  position: number;
  templateElementConfig?: ArrayOfKeyValue;
  type: ElementType;
}

export enum TemplateElementConfigKey {
LABEL,
EMAIL_VALI_TEXT_BEFORE_SEND,
EMAIL_VALI_TEXT_WHILE_SENDING,
EMAIL_VALI_TEXT_AFTER_CONFIRM,
EMAIL_VALI_POLLING,
EMAIL_VALI_HIDE_SAVE_BUTTON,
EMAIL_VALI_SHOW_TAN,
CELLPHONE_VALI_TEXT_BEFORE_SEND,
CELLPHONE_VALI_TEXT_WHILE_SENDING,
CELLPHONE_VALI_TEXT_AFTER_CONFIRM,
CELLPHONE_VALI_HIDE_SAVE_BUTTON,
HINT,
SELECT_ITEMS,
SELECT_TYPE,
REGEX,
VALI_MSG_MANDATORY,
VALI_MSG_REGEX,
UPLOAD_MAX_FILESIZE,
UPLOAD_MAX_FILESIZE_TOTAL,
UPLOAD_ALLOWED_FILETYPES,
WIZARD_STEP,
DOCUMENT_MANUAL_DOWNLOAD,
SERVER_VALIDATED,
CSS_OVERRIDES,
TOOLTIP,
ID_EMAIL_RADIO_SELECT,
ID_EMAIL_INPUT,
WIZARD_SEITE_EMAIL,
ID_MOBIL_RADIO_SELECT,
ID_MOBIL_INPUT,
WIZARD_SEITE_MOBIL,
READONLY,
HIDDEN
}

export interface TemplateElementFileUpload {
  files: TemplateElementFileUploadFile[];
}

export interface TemplateElementFileUploadFile {
  checksum?: string;
  data: string;
  filename: string;
  size?: number;
}

export enum Tenant {
RHEINLAND,
RHION,
CREDITLIFE,
CL_DEVK
}

export enum TimeInterval {
TODAY,
YESTERDAY,
WEEK,
MONTH,
YEAR,
LAST_WEEK,
LAST_MONTH,
LAST_YEAR
}

export interface TimeRange {
  end?: Date;
  start?: Date;
}

export enum TosType {
POSTFACH_NUTZUNGSBEDINGUNGEN
}

export interface ValidatableContactAnswer {
  confirmed: boolean;
  contact: string;
  contactType: ContactType;
}

export interface VermittlerDaten {
  agenturnummer: string;
  anrede?: AnredeKz;
  email?: string;
  fax?: string;
  mobil?: string;
  name1?: string;
  name2?: string;
  ort?: string;
  plz?: string;
  strasse?: string;
  tel?: string;
}


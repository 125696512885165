import { Avatar, Box } from '@mui/material';
import { AppState, DefaultProps } from '../../types/types';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { AnredeKz } from '../../types/apimodel';

interface BrokerInfoProps extends DefaultProps {
    appState:AppState,
}


function BrokerInfo(props:BrokerInfoProps) {
    

    let vermittlerName:string = "";
    let anredeKz = String(props.appState.vermittlerDaten?.anrede);
    if (anredeKz === AnredeKz[AnredeKz.FRAU] || anredeKz === AnredeKz[AnredeKz.FRAU_OHNE_ANDRUCK] || anredeKz === AnredeKz[AnredeKz.FRAEULEIN]  || anredeKz === AnredeKz[AnredeKz.FRAEULEIN_OHNE_ANDRUCK]  || anredeKz === AnredeKz[AnredeKz.HERR]  || anredeKz === AnredeKz[AnredeKz.HERR_OHNE_ANDRUCK]) {
        vermittlerName = '' + props.appState.vermittlerDaten?.name1 + ' ' + props.appState.vermittlerDaten?.name2;
    } else {
        vermittlerName = props.appState.vermittlerDaten?.name1 || '';
    }
    
    let vermittlerTelefon:string = props.appState.vermittlerDaten?.tel || '';
    let email:string = props.appState.vermittlerDaten?.email || '';
    let vermittlerNr:string = (props.appState.vermittlerDaten !== undefined && props.appState.vermittlerDaten !== null && props.appState.vermittlerDaten.agenturnummer !== undefined) ? props.appState.vermittlerDaten.agenturnummer : '0';
    let show:boolean = props.appState.vermittlerDaten !== undefined && props.appState.vermittlerDaten !== null && vermittlerNr !== '0';
    if (props.appState.customizingId === 'rd'){
        show = false;
    }
    if (show === true) {
        return(
            <div className="vermittler-container">            
                <div className="vermittler-ansprechpartner">
                    <div className="vermittler-title">Ihr Kontakt:</div>
                    <div className="vermittler-name">{vermittlerName}</div>
                    <div className="vermittler-telefon"><a href={"tel:"+vermittlerTelefon}><PhoneIcon />{vermittlerTelefon}</a></div>
                    {(email !== undefined && email !== '') &&
                    <div className="vermittler-email"><a href={"mailto:"+email}><MailIcon />{email}</a></div>}
                </div>
                <div className="vermittler-avatar">
                    <Avatar sx={{width: 60, height: 60, objectFit: 'cover'}} 
                    className="user-icon" alt="Hurz" src={(process.env.REACT_APP_BROKER_AVATAR_URL || '') + vermittlerNr + '.jpg'}>
                        <Box sx={{ "& img": {width: 60, height: 60, objectFit: 'cover'}}}>
                            <img src={process.env.REACT_APP_BROKER_FALLBACK_AVATAR_URL} alt="Ansprechpartner" />
                        </Box>
                    </Avatar>
                </div>
            </div>
            )
        } else {
            return (<></>);
        }
}
export default BrokerInfo;